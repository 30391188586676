<template>
  <div>
    <el-row>
  <el-col :span="6" v-for="(item, index) in courses" :key="index" :offset="index > 0 ? 2 : 0">
    <el-card :body-style="{ padding: '0px' }">
      <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
      <div style="padding: 14px;">
        <span>{{ item }}</span>
        <div class="bottom clearfix">
          <el-button type="text" class="button" @click="gostudy(item)">开始学习</el-button>
        </div>
      </div>
    </el-card>
  </el-col>
</el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courses:[]
    };
  },
  created(){
    const user_info=sessionStorage.getItem('user_info');
      this.courses.push(JSON.parse(user_info).results[0].course1)
      this.courses.push(JSON.parse(user_info).results[0].course2)
      this.courses.push(JSON.parse(user_info).results[0].course3)
  },
  methods:{
    gostudy(item){
      this.$router.push({ name: "Classroom",
      query:{
    	classname:item
    } });
    }
  }
}
</script>

<style lang="less" scoped>
  .time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>